import React from 'react'
import {Link} from 'react-router-dom';

function Thankyou(){


  return (
    <div className="container">
      <div style={txtStyle}>
        <p>
          Quote Request Submitted Successfully!
        </p>
        <p>
          We'll email your custom quote within the next 2-3 business days.
        </p>

      </div>
    </div>
  )

}

export default Thankyou;

const txtStyle = {marginBottom: `30px`, position: `relative`, margin: `0 auto`, border: '3px dashed white', width: 500, padding: 40, fontFamily: 'RiftBold', fontSize: '25pt', color: 'white', textAlign: 'center'};
