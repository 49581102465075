import React, { Component } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Upload from './components/pages/Upload';
import Thankyou from './components/pages/Thankyou';

class App extends Component {

  state = {
  }

  render(){

    return(
      <Router>
        <Routes>
          <Route exact path="/" element={<Upload />} />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
      </Router>
    );
  }


}

export default App;
