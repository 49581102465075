import React, {useState, useRef, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
//import {Link} from 'react-router-dom';
import { FileDrop } from 'react-file-drop';
import {Button, Form, FormControl} from 'react-bootstrap';
import '../../Demo.css';
import '../../fonts/fonts.css';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import * as EmailValidator from 'email-validator';


/*

onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
onFrameDrop={(event) => console.log('onFrameDrop', event)}
onDragOver={(event) => console.log('onDragOver', event)}
onDragLeave={(event) => console.log('onDragLeave', event)}
onDrop={(files, event) => console.log('onDrop!', files, event)}

regex phone: ^(\([0-9]{3}\)|[0-9]{3})[- ]?[0-9]{3}[- ]?[0-9]{4}$

*/

const uploadStyle = {marginBottom: `30px`, position: `relative`, margin: `0 auto`, border: '3px dashed white', width: 300, padding: 20, fontFamily: 'RiftBold', fontSize: '25pt', color: 'white'};
const formStyle = {marginBottom: `30px`, position: `relative`, margin: `0 auto`, width: 400, padding: 20, fontFamily: 'RiftBold', fontSize: '25pt', color: 'white'};

function Upload(){

  const navigate = useNavigate();
  const [quoteForm, setQuoteForm] = useState({firstName: null, lastName: null, emailAddress: null, phone: null});
  const [uploadText, setUploadTxt] = useState(parse("UPLOAD RECENT<br />HYDRO BILL"));
  const [uuid, setUuid] = useState(uuidv4());
  const [billStatus, setBillStatus] = useState(false);
  const fileInputRef = useRef(null);
  const protocol = window.location.protocol;

  const uploadFile = (files) =>{

      let percentage = 0;
      let size = Math.round(files[0].size/1000);
      let type = files[0].type;
      let name = files[0].name;
      let formData = new FormData();

      for(let i=0; i<files.length; i++){
        formData.append('file[]', files[i]);
        formData.append('uuid', uuid);
      }

      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", `${protocol}//react.solicasa.ca/upload.php`);
      xhttp.onload = (evt) => {
         if(xhttp.status == 201){
           console.log(xhttp.status);
           setUploadTxt(parse(`UPLOAD COMPLETE <br />${size} KB`));
           setBillStatus(true);
         } else {
           setUploadTxt(parse(`DROP RECENT BILL`));
           setBillStatus(false);
         }
      }

      xhttp.upload.onprogress = (event) => {
        //console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
        percentage = Math.round(100 * event.loaded/event.total);
        setUploadTxt(`${percentage}%`);
      }

      xhttp.send(formData);

  }


  const onFileInputChange = (event) => {

    const { files } = event.target;

    if(files[0] !== undefined){
      uploadFile(files);
    }

  }

  const dropHandler = (files_obj, e) => {
     if(files_obj !== undefined){
       uploadFile(files_obj);
     }
  }

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const handleSubmit = () => {

    const phone = quoteForm.phone.value;
    const firstName = quoteForm.firstName.value;
    const lastName = quoteForm.lastName.value;
    const email = quoteForm.emailAddress.value;

    const phone_regexp = /^(\([0-9]{3}\)|[0-9]{3})[- ]?[0-9]{3}[- ]?[0-9]{4}$/g;
    const name_regexp = /^[a-zA-Z]{2,50}$/g;

    const firstName_matches = firstName.match(name_regexp);
    const lastName_matches = lastName.match(name_regexp);
    const phone_matches = phone.match(phone_regexp);
    const email_valid = EmailValidator.validate(email);

    let errors = '';
    let err = false;

    if(!firstName_matches){
      errors += 'First name required!\n';
      err = true;
    }

    if(!lastName_matches){
      errors += 'Last name required!\n';
      err = true;
    }

    if(!phone_matches){
      errors += 'Phone number required!\n';
      err = true;
    }

    if(!email_valid){
      errors += 'Email address required!\n';
      err = true;
    }

    if(!billStatus){
      errors += 'Please upload a recent hydro bill!\n';
      err = true;
    }

    if(err){
      alert(errors);
    } else {


      let formData = new FormData();
      formData.append('act', 'submit');
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('quote_guid', uuid);

      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", `${protocol}//react.solicasa.ca/json.php`);
      xhttp.onload = (evt) => {
         console.log(xhttp.status);
         console.log(evt.target.response);
         navigate("/thankyou");

      }

      xhttp.send(formData);

      /*
      Axios send to server (THIS DOESN'T WORK FOR SOME REASON)
      axios.post('http://react.solicasa.ca/json.php', {act: 'submit'})
      .then( res => {
        console.log('updated json!');
      })
      */

    }

  }

  const handleChange = event => {
    //const result = event.target.value.toUpperCase();
    //set it!
    let frm = quoteForm;

    frm[`${event.target.id}`].value = frm[`${event.target.id}`].value.toUpperCase();

    setQuoteForm(frm);

    //console.log(frm[`${event.target.id}`].value);
    //console.log(event.target.id);
    //console.log(quoteForm[`${event.target.id}`].value);
  }

  return (
    <div className="container">
      <div>
        <div style={uploadStyle}>
          <FileDrop onDrop={dropHandler} onTargetClick={onTargetClick}>
             {uploadText}
          </FileDrop>
        </div>
        <div style={formStyle}>
        <Form noValidate>

            <Form.Group className="mb-3" controlId="firstName">
              <Form.Control type="text" placeholder="First Name"  ref={input=>quoteForm.firstName = input} onChange={handleChange} />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastName">
              <Form.Control type="text" placeholder="Last Name"  ref={input=>quoteForm.lastName = input} onChange={handleChange} />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="emailAddress">
              <Form.Control type="email" placeholder="Email Address" ref={input=>quoteForm.emailAddress = input}  onChange={handleChange} />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="phone">
              <Form.Control type="text" placeholder="Phone Number"  ref={input=>quoteForm.phone = input} />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <center><Button variant="light" type="button" onClick={handleSubmit}>Submit</Button></center>

        </Form>
        </div>
      </div>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        style={{display: 'none'}}
      />
    </div>

  )
}

export default Upload;

const txtStyle = {
  color: '#333',
}
